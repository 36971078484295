<template>
  <div class="wrapper" id="operationLog">
    <ctheader></ctheader>
    <div class="content" ref="operationLog">
      <div class="table-head">
        <div class="left">
          <select class="w150" v-model="olSearch.opType" @change="initTbody">
            <option value="">全部操作类型</option>
            <option v-for="row in olType" :value="row.id">{{
              row.value
            }}</option>
          </select>
          <input
            type="text"
            class="w150 search"
            placeholder="操作用户"
            @keypress.enter="initTbody"
            v-model="olSearch.adminName"
          />
          <i class="sdnm-icon sdnm-search" @click="initTbody"></i>
          <input
            type="text"
            class="w150 search"
            placeholder="用户ip"
            @keypress.enter="initTbody"
            v-model="olSearch.ip"
          />
          <i class="sdnm-icon sdnm-search" @click="initTbody"></i>
          <!-- <flatpickr
            :width="200"
            :config="flatpickrConfig"
            name="noPayTime"
            placeholder="请选择操作日期区间"
            :getDateCallback="getDate"
          ></flatpickr> -->
          <el-date-picker
            v-model="olSearch.expiration_date"
            type="daterange"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="getDate"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%" :style="{ 'min-width': '800px' }">
          <thead>
            <tr>
              <th class="pw25">操作功能</th>
              <th class="pw25">操作用户</th>
              <th class="pw25">用户IP</th>
              <th>操作时间</th>
            </tr>
          </thead>
          <tbody :style="{ height: oltbHeight + 'px' }">
            <template v-for="row in olTbody">
              <tr @click.stop="openIt(row)">
                <td class="pw25 ellipsis" :title="row.op_type">
                  <i
                    :class="{
                      'sdnm-enter': !row.open,
                      'sdnm-unfold': row.open,
                    }"
                  ></i
                  >{{ row.op_type }}
                </td>
                <td class="pw25 ellipsis" :title="row.admin_name">
                  {{ row.admin_name }}
                </td>
                <td class="pw25 ellipsis" :title="row.ip">{{ row.ip }}</td>
                <td class="pw25 ellipsis" :title="row.created_at">
                  {{ row.created_at }}
                </td>
              </tr>
              <tr class="pdl20" v-if="row.open">
                <td class="pw80 ellipsis" :title="row.old_value">
                  操作前的值：{{ row.old_value }}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="pdl20" v-if="row.open">
                <td class="pw80 ellipsis" :title="row.new_value">
                  操作后的值：{{ row.new_value }}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </template>
            <tr v-if="olTbody.length === 0">
              <td class="empty" colspan="7">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :start="olSearch.start"
        :total="olSearch.total"
        :page-length="olSearch.length"
        :pageCallback="page"
      ></pagination>
    </div>
  </div>
</template>

<script>
/**
 * @module app/operation/operationLog
 * @desc 传蔬农贸版后台 操作日志
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-10
 * @copyright 2021
 */
import { mapGetters, mapActions } from "vuex";
import { tableResize, apiPost } from "../../modules/utils";
// import ctheader from '@@/ctheader';
// import pagination from '@@/pagination';
// import flatpickr from '@@/flatpickr';
import ctheader from "../../components/ctheader";
import pagination from "../../components/pagination";
import flatpickr from "../../components/flatpickr";

import APIs from "../../modules/APIs";
// import router from '@/router';
// import { event } from '_/EVENTs';
// import * as URL from '_/URLs';
const getters = mapGetters([]);
const actions = mapActions(["setAlert"]);
export default {
  name: "operationlog",
  computed: {
    ...getters,
  },
  components: { ctheader, pagination, flatpickr },
  beforeRouteEnter(to, from, next) {
    let getOlTbody = new Promise(function(resolve, reject) {
      apiPost({
        url: APIs.LOG,
        data: {
          action: "log.list",
          start: 0,
          length: 10,
          op_type: "",
          admin_name: "",
          ip: "",
          time_begin: "",
          time_end: "",
        },
        success(res) {
          resolve(res);
        },
        error(res) {
          reject(res);
        },
      });
    });
    let getOlType = new Promise(function(resolve, reject) {
      apiPost({
        url: APIs.LOG,
        data: {
          action: "log.type.list",
          keyword: "",
          position_id: "",
          start: 0,
          length: 10,
        },
        success(res) {
          resolve(res);
        },
        error(res) {
          reject(res);
        },
      });
    });
    Promise.all([getOlTbody, getOlType])
      .then((values) => {
        next((vm) => {
          if (values[0].code === "0") {
            vm.olTbody = values[0].data.map((el) => {
              el.open = false;
              return el;
            });
            vm.olSearch.total = values[0].total;
          } else {
            vm.olTbody = [];
            vm.olSearch.total = 0;
          }
          if (values[1].code === "0") {
            vm.olType = values[1].data;
          } else {
            vm.olType = [];
          }
          vm.tResize();
          window.addEventListener("resize", vm.tResize, false);
        });
      })
      .catch((values) => {
        next();
      });
  },
  created() {},
  beforeDestroy() {
    window.removeEventListener("resize", this.tResize, false);
  },
  data() {
    return {
      pageInfo: {}, // 标题信息
      olSearch: {
        start: 0,
        length: 10,
        total: 0,
        opType: "",
        adminName: "",
        ip: "",
        startDate: "",
        endDate: "",
      }, // 固定柜组搜索内容
      oltbHeight: "", // 表格高度
      olTbody: [], // 表格数据
      olType: [], // 操作类型
      flatpickrConfig: {
        minDate: null,
        maxDate: null,
      }, // 搜索的时间组件的配置
      open: false,
    };
  },
  methods: {
    ...actions,
    /**
     * 打开关闭操作值
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-09
     * @param  {Object}   row
     */
    openIt(row) {
      row.open = !row.open;
      if (row.open) {
        this.oltbHeight += 82;
      } else {
        this.oltbHeight -= 82;
      }
    },
    /**
     * 换页方法
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-09
     * @param  {Number}   index
     */
    page(index) {
      this.olSearch.start = index;
      this.getOlTbody();
    },
    /**
     * 表格高度重置
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-09
     */
    tResize() {
      this.oltbHeight = tableResize(
        this.$refs.operationLog.clientHeight,
        250,
        this.olTbody.length
      );
    },
    /**
     * 获取时间
     * @author songkexin <songkexin@rongyi.com>
     * @date 2021-11-28
     * @param  {Object, String}   dates 选中的时间区间 name
     */
    getDate(dates) {
      this.olSearch.startDate = dates[0];
      this.olSearch.endDate = dates[1];
      this.initTbody();
    },
    /**
     * 初始化固定柜组的表格
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-09
     */
    initTbody() {
      this.olSearch.start = 0;
      this.olSearch.total = 0;
      this.getOlTbody();
    },
    /**
     * 获取固定柜组的数据
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-01-09
     */
    getOlTbody() {
      let $this = this;
      apiPost({
        url: APIs.LOG,
        data: {
          action: "log.list",
          start: this.olSearch.start,
          length: 10,
          op_type: this.olSearch.opType,
          admin_name: this.olSearch.adminName,
          ip: this.olSearch.ip,
          time_begin: this.olSearch.startDate,
          time_end: this.olSearch.endDate,
        },
        success(res) {
          if (res.code === "0") {
            $this.olTbody = res.data;
            $this.olSearch.total = res.total;
            $this.tResize();
          } else {
            $this.olTbody = [];
            $this.olSearch.total = 0;
          }
        },
        error(res) {
          $this.olTbody = [];
          $this.olSearch.total = 0;
        },
      });
    },
  },
};
</script>

<style lang="less">
@import url("../../less/common.less");
#operationLog .lf96 {
  left: 96px;
}
#operationLog {
  .pdl20 {
    .pdl(20px);
  }
}
#operationLog .topTree {
  .va-t();
}
#operationLog .form {
  .field {
    &.h80 {
      height: 80px;
    }
  }
  .field:first-child {
    label {
      .pdl(0);
    }
  }
  .field:nth-child(2) {
    label {
      .pdl(27px);
    }
  }
}
</style>
